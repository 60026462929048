/* global document, IntersectionObserver */

import SmoothScroll from 'smooth-scroll';
import news from './news';

if (module.hot) {
  module.hot.accept();
}

document.addEventListener('DOMContentLoaded', () => {
  // Observer fun
  // const targets = document.querySelectorAll('section');

  // const options = {
  //   root: null,
  //   rootMargin: '0px',
  //   threshold: 1.0,
  // };

  // const callback = (entries) => {
  //   const relevantEntry = entries.find(entry => entry.isIntersecting);

  //   if (!relevantEntry) {
  //     return;
  //   }

  //   console.log('intersection', entries.length, relevantEntry);
  // };

  // const observer = new IntersectionObserver(callback, options);

  // targets.forEach(target => observer.observe(target));

  SmoothScroll('a[href*="#"]', {
    header: '.header',
    updateURL: false,
  });

  const hamburger = document.querySelector('.mobile-nav-trigger');
  const nav = document.querySelector('nav');
  const links = nav.querySelectorAll('a');

  hamburger.addEventListener('click', () => {
    hamburger.classList.toggle('active');
    nav.classList.toggle('active');

    if (nav.style.maxHeight) {
      nav.style.maxHeight = null;
    } else {
      nav.style.maxHeight = `${nav.scrollHeight}px`;
    }
  });

  links.forEach((link) => {
    link.addEventListener('click', () => {
      nav.classList.remove('active');
      hamburger.classList.remove('active');
      nav.style.maxHeight = null;
    });
  });

  const accs = document.querySelectorAll('.accordion');

  accs.forEach((acc) => {
    acc.addEventListener('click', () => {
      acc.classList.toggle('active');
      const panel = acc.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = `${panel.scrollHeight}px`;
      }
    });
  });

  news.init();
});
