/* global document */

let selectedFilterTypes = [];
let newsItems;

export default {
  init() {
    newsItems = document.querySelectorAll('.news-item');
    const filterButtons = document.querySelectorAll('.filter');

    filterButtons.forEach((filter) => {
      filter.addEventListener('click', () => {
        if (filter.classList.contains('active')) {
          this.removeFilter(filter.dataset.type);
          filter.classList.remove('active');
        } else {
          this.addFilter(filter.dataset.type);
          filter.classList.add('active');
        }
      });
    });
  },

  removeFilter(type) {
    selectedFilterTypes = selectedFilterTypes.filter(filterType => filterType !== type);
    this.showHideNewsItems();
  },

  addFilter(type) {
    selectedFilterTypes.push(type);
    this.showHideNewsItems();
  },

  showHideNewsItems() {
    if (!selectedFilterTypes.length) {
      newsItems.forEach(newsItem => newsItem.classList.remove('hidden'));
      return;
    }

    newsItems.forEach((newsItem) => {
      if (selectedFilterTypes.includes(newsItem.dataset.type)) {
        newsItem.classList.remove('hidden');
      } else {
        newsItem.classList.add('hidden');
      }
    });
  },
};
